import React, { createRef, useEffect, useState } from 'react';
import math from 'canvas-sketch-util/math'
import random from 'canvas-sketch-util/random';
import Color from 'canvas-sketch-util/color'
import risoColors from 'riso-colors'

// import styled from 'styled-components'

let settings = {}

settings.width = 1080 
settings.height = 1080
settings.buffer = 700
settings.speed = 2

// const seed = '599404';
var seed = Date.now();

export default function Animation() {

const [nClick, setNClick] = useState(0);
  
const canvasRef = createRef();

    // CODE THAT RUNS ONLY ONCE
    random.setSeed(seed);
    console.log(seed)

    let x, y, w, h, fill, stroke, blend;

    const num = 40;
    const degrees = -30;

    var rects = [];
    var rectColors = [
        random.pick(risoColors),
        random.pick(risoColors),
    ];

    // var bgColor = random.pick(risoColors).hex;
    // var bgColor = 'black';

    var mask = {
        radius: settings.width * 0.4,
        sides: 3,
        x: settings.width * 0.5,
        y: settings.height * 0.58,
    };

    for (let i = 0; i < num; i++) {
        x = random.range(0, settings.width);
        y = random.range(0, settings.height);
        w = random.range(600, settings.width);
        h = random.range(40, 200);

        fill = random.pick(rectColors).hex;
        stroke = random.pick(rectColors).hex;

        blend = (random.value() > 0.5) ? 'overlay' : 'source-over';

        rects.push({ x, y, w, h, fill, stroke, blend });
    }


  const draw = (context, frameCount) => {

    const width = context.canvas.width
    const height = context.canvas.height


    seed = Date.now();
    random.setSeed(seed);
    rectColors = [
        random.pick(risoColors),
        random.pick(risoColors),
    ];
  
    // bgColor = random.pick(risoColors).hex;
    // bgColor = 'black';
  
    mask = {
        radius: settings.width * 0.4,
        sides: 3,
        // sides: random.range(3,5),
        x: settings.width * 0.5,
        y: settings.height * 0.58,
    };
  
    for (let i = 0; i < num; i++) {
        x = random.range(0, settings.width);
        y = random.range(0, settings.height);
        w = random.range(600, settings.width);
        h = random.range(40, 200);
  
        fill = random.pick(rectColors).hex;
        stroke = random.pick(rectColors).hex;
  
        blend = (random.value() > 0.5) ? 'overlay' : 'source-over';
  
        rects.push({ x, y, w, h, fill, stroke, blend });
    }
    

    
    //CODE THAT RUNS REPEATEDLY
    // context.fillStyle = bgColor;
    // context.fillRect(0, 0, width, height);


    context.save();
    context.translate(mask.x, mask.y);

    drawPolygon({ context, radius: mask.radius, sides: mask.sides });

    context.clip();


    rects.forEach(rect => {
        const { x, y, w, h, fill, stroke, blend } = rect;
        let shadowColor;

        context.save();
        context.translate(-mask.x, -mask.y);
        context.translate(x, y);

        context.strokeStyle = stroke;
        context.fillStyle = fill;
        context.lineWidth = 10;

        context.globalCompositeOperation = blend;

        drawSkewedRect({ context, w, h, degrees });

        shadowColor = Color.offsetHSL(fill, 0, 0, -20);
        shadowColor.rgba[3] = 0.5;

        context.shadowColor = Color.style(shadowColor.rgba);
        context.shadowOffsetX = -10;
        context.shadowOffsetY = 20;
        
        context.fill();

        context.shadowColor = null;
        context.stroke();

        context.globalCompositeOperation = 'source-over';

        context.lineWidth = 2;
        context.strokeStyle = 'black';
        context.stroke();

        context.restore();
    });

    context.restore();

    // polygon outline
    context.save();
    context.translate(mask.x, mask.y);
    context.lineWidth = 20;

    drawPolygon({ context, radius: mask.radius - context.lineWidth, sides: mask.sides });

    context.globalCompositeOperation = 'color-burn';
    context.strokeStyle = rectColors[0].hex;
    context.stroke();

    context.restore();



  };

  const onMouseDown = () => {
    setNClick(nClick => nClick + 1)
  }

  //renders repeatedly
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.canvas.width = settings.width
    context.canvas.height = settings.height
    canvas.addEventListener('mousedown', onMouseDown)
    let frameCount = 0;
    // let animationFrameId;
    const render = () => {
      frameCount++;
      draw(context, frameCount);
      // animationFrameId = window.requestAnimationFrame(render);
    };
    render();
    return () => {
      // window.cancelAnimationFrame(animationFrameId);
      canvas.removeEventListener('mousedown', onMouseDown)
    };
  },[nClick]);
  
  return <canvas style={{ 
    width: '100%',
    maxWidth: '800px',
    height: '100%',
    maxHeight: '800px'

  }} ref={canvasRef} />;
}

const drawSkewedRect = ({ context, w = 600, h = 200, degrees = -45 }) => {
	const angle = math.degToRad(degrees);
	const rx = Math.cos(angle) * w;
	const ry = Math.sin(angle) * w;
	
	context.save();
	context.translate(rx * -0.5, (ry + h) * -0.5);

	context.beginPath();
	context.moveTo(0, 0);
	context.lineTo(rx, ry);
	context.lineTo(rx, ry + h);
	context.lineTo(0, h);
	context.closePath();
	
	context.restore();
};

const drawPolygon = ({ context, radius = 100, sides = 3 }) => {
	const slice = Math.PI * 2 / sides;

	context.beginPath();
	context.moveTo(0, -radius);

	for (let i = 1; i < sides; i++) {
		const theta = i * slice - Math.PI * 0.5;
		context.lineTo(Math.cos(theta) * radius, Math.sin(theta) * radius);
	}

	context.closePath();
};

// export const Canvas = styled.canvas`   
//   height: 50%;
//   width: 50%;
//   margin: auto;
  
// `