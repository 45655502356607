import React from 'react'
import styled from 'styled-components'

import {H3, SubSectionContainer, TextArea} from '../style/MyTags'

import happyFace from '../../images/sentiment/happy.png'
import sadFace from '../../images/sentiment/sad.png'
import neutralFace from '../../images/sentiment/neutralGIF.gif'


var Sentiment = require('sentiment')

export default function SentimentAnalysis() {
    const [score, setScore] = React.useState(0);

    const analyseSentiment = (event) => {
        var txt = event.target.value
        var sentiment = new Sentiment()
        var result = sentiment.analyze(txt)
        setScore(result.score)
        // console.log(result)
    }
    

    return (
      <>
          <SubSectionContainer>
              <TextArea 
              type='text' 
              name='inputSentiment' 
              placeholder='Enter a word, phrase or paragraph'
              onChange={analyseSentiment}
              />
              <Image 
              src={
                  score > 0 ? happyFace 
                  : score < 0 ? sadFace 
                  : neutralFace} />
          </SubSectionContainer>
      </>
    )
}


const Image = styled.img`
  width: 100px;
  height: 100px;
  margin-left: 20px;
  flex: 1;
  border-radius: 10px;
  object-fit: cover;
  &:hover {
    transform: scale(0.7);
    transition: transform 0.2s ease-out;
  };

`

