/* eslint-disable */

import React from 'react';
import styled from 'styled-components'
import { Section, H3, MyTheme, Bounce } from '../style/MyTags';

import AnimationHeading from '../animation/AnimateHeading';

import jobs from '../../text/employment';

export default function Employment() {

  const [expandEmployment, setExpandEmployment] = React.useState([false]);
  
  React.useEffect(() => {

    let expandEmp = Array(jobs.length).fill(false)
    setExpandEmployment(expandEmp)

  },[]);

  return (
	<>
		<Section>
			<AnimationHeading text='Employment' />
			<Bounce>click any to expand</Bounce> 
			{jobs.map((job, iJob) => (
				<ListMain 
				key={'job-'+iJob}
				open={expandEmployment[iJob]}
				onClick={() => {
					let expandEmp = [...expandEmployment]
					expandEmp[iJob] = !expandEmp[iJob]
					setExpandEmployment(expandEmp)
				}}>
					<Title>{job.title}</Title> 
					<TextContainer>
						<TextCompany>{job.company + ', ' + job.location}</TextCompany>
						<TextYear> {job.date}</TextYear>
					</TextContainer>
					<ListDetails open={expandEmployment[iJob]}>
						<ul>
							{job.description.map((item, iItem) => (
								<li key={iJob + iItem}>{item}</li>
							))}
						</ul>
					</ListDetails>
				</ListMain>
			))}
		</Section>
	</>
  );
}

const Title = styled(H3)`
	margin: 0;
	padding: 0;
  
`
const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const TextCompany = styled.i`
	font-weight:  bold;
`

const TextYear = styled.span`
	font-weight:  bold;
  	font-style: normal;
`

const ListMain = styled.div`
	border-radius: 4px;
	margin: 5px;
	padding: 5px;
	background-color: ${MyTheme.bgColor.item};
	overflow: hidden;
	&:hover {
		background-color: ${MyTheme.bgColor.page};
	}
	&:hover ${H3} {
		color: ${MyTheme.color.h3Hover};
		cursor: pointer;
	}
`;

const ListDetails = styled.div`
	font-size: 1em;
	max-height: ${({ open }) => (open ? '1000px' : '0')};
	overflow: hidden;
	transition: max-height 1s ease-in-out;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		position: relative;
		padding-left: 1.5em;
		margin: 0.25em;
	}

	li:before {
		content: '✔';
		position: absolute;
		left: 0;
	}
`