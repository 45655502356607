/* eslint-disable */

import React, {useState, useRef} from 'react';
import styled, { keyframes } from 'styled-components'

import { Section, Gap, Text, MyTheme, PageContainer} from '../components/style/MyTags';
import AnimateHeading from '../components/animation/AnimateHeading';

import BouncingPoints from '../components/animation/BouncingPoints';
import ClippedShape from '../components/animation/ClippedShape'

export default function BouncingPointsScreen() {

	const gap = 10;

  return (
	<PageContainer>

		<Section style={{padding: '0px'}}>
			<AnimateHeading text='Showcase: Bouncing Points' />
			<Text>Click to increase speed and reverse direction</Text>
			<BouncingPoints />
		</Section>
		<Gap px={gap}/>

		<Section>
			<AnimateHeading text='Showcase: Clipped Shape' />
			<CanvasHolder>
				<ClippedShape />
			</CanvasHolder>
		</Section>

	</ PageContainer>
  );
}


const CanvasHolder= styled.div`
	display: flex;
	justify-content: center;
`
