import React from 'react';
import LazyLoad from 'react-lazy-load';
import styled from 'styled-components';
import { Section, Gap, PageContainer, SwipeIcon } from '../components/style/MyTags';

import AnimateHeading from '../components/animation/AnimateHeading';

import Employment from '../components/section/Employment';
import Education from '../components/section/Education';
import ProLanguages from '../components/section/ProLanguages';

import SphericalWordCloud from '../components/3d/SphericalWordCloud';

export default function ResumeScreen() {
  const gap = 10;

  return (
    <PageContainer>

		<LazyLoad>
			<Section>
				<AnimateHeading text="Identity Sphere" />
				<SphereHolder>
					<SphericalWordCloud />
				<SwipeIcon />
				</SphereHolder>
			</Section>
		</LazyLoad>
		<Gap px={gap} />		

		<LazyLoad>
			<ProLanguages />
		</LazyLoad>
		<Gap px={gap} />

		<LazyLoad>
			<Employment />
		</LazyLoad>
		<Gap px={gap} />

		<LazyLoad>
			<Education />
		</LazyLoad>
		<Gap px={gap} />
    </PageContainer>
  );
}

const SphereHolder = styled.div`
  position: relative;
  height: 80vw;
  max-height: 600px;
  overflow: hidden;
`;