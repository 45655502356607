/* eslint-disable */

import './App.css';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'

import Nav from './components/nav/Nav';

//screens
import Resume from './screens/ResumeScreen';
import MandalaVisualiser from './screens/MandalaScreen';
import Portrait from './screens/ParticlePortraitScreen';
import BouncingPoints from './screens/BouncingPointsScreen';
import AIScreen from './screens/AISreen';
import CPVisualiser from './screens/CPVisualiserScreen';


//IMPORTANT TASKS-------------------------------------
// calculate the most common color then do actions on it
//change movement parameters based on colour


//REQUIRED TASKS BUT NOT URGENT
//use AI face detection libraries to detect where facial features are so animation can be done around it

//JUST FOR FUN------------------------------------------
//word diagrams that take a website as an input and draw the most frequent words big
//speech recognition that changes the colour of the website and also for other inputs
//photo effects using creative coding such as portrait made of words
//trying folding sqaures to transition the sidebar
//at the bottom of the resume, include an option to see my portfolio. This portfolio selector can be a grid of images.
//add a world that zooms out and becomes the dot to the 'j' in 'Anjuman'
//do 3d modelling of my head

//COMPLETED TASKS---------------------------------------
//DONE check why the sub pages dont work react-router
//DONE add delay to when components mount the screen, could help with intial lag
//DONE add light shawdowing for the text
//DONE add animation delay to each section of PageContainer
//DONE separate the audio analysis component from the visualiser so it can be used in other components
//DONE in the particle portrait make all particles start from the middle and go to their positions
//DONE fix hover scaling of UNSW
//DONE add touch icon to identity sphere
//DONE add link to programming languages logo
//DONE for the programming languages section, just move left to right instead of going a full loop
//DONE add a page for all the random artworks
//DONE check why particle portrait still has double particles
//DONE add touch function for particle portrait
//DONE add AI/machine learning tools to let users input a sentence and it tells them whether it is negative or positive
//DONE: look at making each section tilt like the website from the animation video
//DONE: add text banner that goes from right to left then to the other side of the screen
//DONE: add text that wiggles such as 'click to expand'
//DONE add DOM Router for CPU heavy artworks
//DONE stop sphere from double rendering
//DONE fix slowing down issue when navigating to different pages
//DONE add gap to nav component instead so I dont have to repeat it for every page


function AppContainer() {


  return (
	<div className='App'>

		{/* //navigation bar and floating sidebar */}
    	<Nav /> 

    	<Routes>
		<Route path ='/' element={<Resume />}></Route>
		<Route path ='/ai' element={<AIScreen />}></Route>
		<Route path ='/mandala-visualiser' element={<MandalaVisualiser />}></Route>
		<Route path ='/particle-visualiser' element={<CPVisualiser />}></Route>
		<Route path ='/portrait' element={<Portrait />}></Route>
		<Route path ='/bouncing-points' element={<BouncingPoints />}></Route>
		</Routes>

	</div>
  );
}

function App() {

	// console.disableYellowBox = true;
	return (
		<>
			<BrowserRouter>
				<AppContainer />
			</BrowserRouter>
		</>

	);
};

export default App;
