import * as THREE from 'three'
import React, { useRef, useState, useMemo, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Text, OrbitControls, Stars } from '@react-three/drei'
// import randomWord from 'random-words'

function randomElement(arr) {
  	return arr[Math.floor(Math.random() * arr.length)];
}

let allWords = ['Anjuman','Engineer', 'Scientist', 'Australia', 'Sydney', 'Wollongong', 
'Guitar', 'Music Producer', 'Nepal', 'AI', 'Python', 'SQL', 'React', 'JavaScript', 'C', 'HTML',
'CSS', 'MATLAB', 'VBA', 'Machine Learning', 'UNSW', 'Developer', 'Solutions Expert',
'Gym', 'Soccer',
'Anjuman','Engineer', 'Scientist', 'Australia', 'Sydney', 'Wollongong', 
'Guitar', 'Music Producer', 'Nepal', 'AI', 'Python', 'SQL', 'React', 'JavaScript', 'C', 'HTML',
'CSS', 'MATLAB', 'VBA', 'Machine Learning', 'UNSW', 'Developer', 'Solutions Expert',
'Gym', 'Soccer'
]

function SphericalWordCloud(props) {

	function Word({ children, ...props }) {

		const color = new THREE.Color()
		const fontProps = { fontSize: 2.5, letterSpacing: -0.05, lineHeight: 1, 'material-toneMapped': false }
		const wordRef = useRef()
		const [hovered, setHovered] = useState(false)

		//user interactions

		const over = (e) => {
			e.stopPropagation() 
			setHovered(true)
		}

		const out = () => setHovered(false)

		const clicked = () => {
			console.log(children)
		}

		// Change the mouse cursor on hover
		useEffect(() => {
			if (hovered) document.body.style.cursor = 'pointer'
			return () => (document.body.style.cursor = 'auto')
		}, [hovered])

		// Tie component to the render-loop
		useFrame(({ camera }) => {

		// Make text face the camera
		wordRef.current.quaternion.copy(camera.quaternion)

		// Animate font color
		// wordRef.current.material.color.lerp(color.set(hovered ? '#fa2720' : 'white'), 0.1)
		wordRef.current.material.color.lerp(color.set(hovered ? '#20fad9' : 'white'), 0.1)

		})

		return (
			<Text
			ref={wordRef}
			onPointerOver={over}
			onPointerOut={out}
			onClick={clicked}
			{...props}
			{...fontProps}
			children={children} />
		)
	}

	// Create a count x count random words with spherical distribution
	function Cloud({ count, radius }) {
		const words = useMemo(() => {
		  const temp = [];
		  const inc = Math.PI * (3 - Math.sqrt(5));
		  const offset = 2 / count;
		  
		  for (let i = 0; i < count; i++) {
			const y = i * offset - 1 + offset / 2;
			const r = Math.sqrt(1 - y * y);
			const phi = i * inc;
			const x = Math.cos(phi) * r;
			const z = Math.sin(phi) * r;
			
			const position = new THREE.Vector3(x, y, z).multiplyScalar(radius);
			const word = props.words === undefined ? randomElement(allWords) : randomElement(props.words);
			
			temp.push([position, word]);
		  }
		  
		  return temp;
		}, [count, radius]);
		
		return (
		  words.map(([position, word], index) => (
			<Word key={index} position={position} children={word} />
		  ))
		);
	  }


	return (
		<Canvas style={{backgroundColor: 'black'}} dpr={[1, 2]} camera={{ position: [0, 0, 35], fov: 90 }}>
			<Stars
				radius={300}
				depth={60}
				count={20000}
				factor={7}
				saturation={0}
				fade={true}
			/>
			{/* fog blurs the distance  */}
			<fog attach="fog" args={['#202025', 0, 80]} />
			<Cloud  count={64} radius={20} />
			{/* <TrackballControls /> */}
			<OrbitControls
				autoRotate={true}
				enableDamping={true}
				enableZoom={false}
				enablePan={false}
				enableRotate={true}
				zoomSpeed={1}
				panSpeed={0.5}
				rotateSpeed={0.8}/>
		</Canvas>
	)
}

export default React.memo(SphericalWordCloud)