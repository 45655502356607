/** @type {CanvasRenderingContext2D} */

import React from 'react';
import math from 'canvas-sketch-util/math'
import random from 'canvas-sketch-util/random';
import eases from 'eases'

import face from '../../images/portrait/image-03.png'

let animationFrameId;
let imgA 

const cursor = { x: 9999, y: 9999}

export default function ParticlePortrait() {
	const startRef = React.useRef(false)
	const countRef = React.useRef(0)
	const canvasRef = React.useRef()
	const width = 1080
	const height = 1080

	const particles = [];
	

	//CODE THAT IS CALLED ONCE
	const sketch = (context, canvas) => {

		let x, y, particle, radius
		
		const imgACanvas = document.createElement('canvas')
		const imgAContext = imgACanvas.getContext('2d')

		imgACanvas.width = imgA.width
		imgACanvas.height = imgA.height
	
		imgAContext.drawImage(imgA, 0, 0)

		const imgAData = imgAContext.getImageData(0,0,imgA.width, imgA.height).data


		const numCircles = 30
		const gapCircle = 2
		const gapDot = 2
		let dotRadius = 8
		let cirRadius = 0
		// let scale = 0.8
		const fitRadius = dotRadius

		let ix, iy, idx, r, g, b, colA, colorPref
		for (let i = 0; i < numCircles; i++) {
			const circumference = Math.PI * 2 * cirRadius;
			const numFit = i ? Math.floor(circumference / (fitRadius * 2 + gapDot)) : 1;
			const fitSlice = Math.PI * 2 / numFit;
			

			for (let j = 0; j < numFit; j++) {
				const theta = fitSlice * j;

				x = Math.cos(theta) * cirRadius //* scale
				y = Math.sin(theta) * cirRadius //* scale

				x += width  * 0.5;
				y += height * 0.5;

				ix = Math.floor((x / width)  * imgA.width);
				iy = Math.floor((y / height) * imgA.height);
				idx = (iy * imgA.width + ix) * 4;

				r = imgAData[idx + 0];
				g = imgAData[idx + 1];
				b = imgAData[idx + 2];
				colA = `rgb(${r}, ${g}, ${b})`;

				colorPref = (r + 2 * 255 - g - b)
				radius = math.mapRange(colorPref, 0, 765, 2, 8);
				
				particle = new Particle({ x, y, radius, colA });
				particles.push(particle);
			}

			cirRadius += fitRadius * 2 + gapCircle;
			dotRadius = (1 - eases.quadOut(i / numCircles)) * fitRadius;
		}

		// runs repeatedly
		const render = () => {
			window.cancelAnimationFrame(animationFrameId);
			context.clearRect(0, 0, canvas.width, canvas.height);
			// context.fillStyle = 'black';
			// context.fillRect(0, 0, width, height)
	
			particles.sort((a,b) => a.scale - b.scale)

			//start by making everything explode
			if (countRef.current === 0) explode()
			countRef.current +=1
			// console.log(countRef)

	
			particles.forEach(particle => {
				particle.update() //brings it back to original position
				particle.wobble() //randondomly move all the particles slightly
				particle.draw(context)
				
			})
			
			animationFrameId = requestAnimationFrame(render);
		};
		render();

		canvas.addEventListener('mousedown', onMouseDown)
		canvas.addEventListener('touchstart', onMouseDown);
		window.addEventListener('keydown', onKeyDown)

	};

	//initialises
	React.useEffect(() => {

		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');
		context.canvas.width = width
		context.canvas.height = height

		console.log('rendered')
		animationFrameId = 0

		const start = async() => {
			imgA = await loadImage(face)
			return sketch(context, canvas);
		}
		if (!startRef.current) {
			start()
		} else {
			startRef.current = true
		}

		return () => {
			window.cancelAnimationFrame(animationFrameId);
			canvas.removeEventListener('mousedown', onMouseDown);
			window.removeEventListener('mousemove', onMouseMove);
			window.removeEventListener('mouseup', onMouseUp);
			window.removeEventListener('keydown', onKeyDown);
		};

	},[]);

	const explode = () => {
		particles.forEach(particle => {
			particle.x = width / 2
			particle.y = width / 2
			
		})
	}

	const onKeyDown = (e) => {
		console.log(e.key)
		if(e.key === 'e'){
			explode()
		}
	}

	const onMouseDown = (e) => {
		window.addEventListener('mousemove', onMouseMove);
		window.addEventListener('touchmove', onTouchMove, { passive: false });
		window.addEventListener('mouseup', onMouseUp);
		window.addEventListener('touchend', onTouchEnd, { passive: false });
		onMouseMove(e);
	  };
	
	const onMouseMove = (e) => {
		const x = (e.offsetX / canvasRef.current.offsetWidth) * canvasRef.current.width
		const y = (e.offsetY / canvasRef.current.offsetHeight) * canvasRef.current.height
		cursor.x = x;
		cursor.y = y
	}

	const onTouchMove = (e) => {
		e.preventDefault();
		const touch = e.changedTouches[0];
		const rect = canvasRef.current.getBoundingClientRect();
		const x = (touch.clientX - rect.left) * (canvasRef.current.width / rect.width);
		const y = (touch.clientY - rect.top) * (canvasRef.current.height / rect.height);
		cursor.x = x;
		cursor.y = y;
	  };

	const onMouseUp = () => {
		window.removeEventListener('mousemove', onMouseMove)
		window.removeEventListener('mouseup', onMouseUp)
		cursor.x = 9999
		cursor.y = 9999
	}

	const onTouchEnd = () => {
		window.removeEventListener('touchmove', onTouchMove);
		window.removeEventListener('touchend', onTouchEnd);
		cursor.x = 9999;
		cursor.y = 9999;
	  };

	const loadImage = async (url) => {
		return new Promise((resolve, reject) => {
			const img = new Image()
			img.onload = () => resolve(img)
			img.onerror = () => reject()
			// img.crossOrigin = 'anonymous'
			img.src = url
		})
	}

	// return <canvas style={{ width: '100%' , height: '100%'}} ref={canvasRef} />;
	return <canvas 
	style={{ 
		width: '100%',
		maxWidth: '800px',
		height: '100%',
		maxHeight: '800px',
		// overflow: 'auto',
	  }}
	 ref={canvasRef} />;
	  
}

class Particle {
	constructor({ x, y, radius = 10, colA }) {
		// position
		this.x = x;
		this.y = y;

		// acceleration
		this.ax = 0;
		this.ay = 0;

		// velocity
		this.vx = 0;
		this.vy = 0; 

		// initial position
		this.ix = x;
		this.iy = y;

		this.radius = radius;
		this.scale = 1;
		this.color = colA;

		this.minDist = random.range(100, 200);
		this.pushFactor = random.range(0.01, 0.02);
		this.pullFactor = random.range(0.006, 0.010);
		this.dampFactor = random.range(0.95, 0.95);
	}


	update() {
		let dx, dy, dd, distDelta;

		// pull force
		dx = this.ix - this.x;
		dy = this.iy - this.y;
		dd = Math.sqrt(dx * dx + dy * dy);

		this.ax = dx * this.pullFactor;
		this.ay = dy * this.pullFactor;

		this.scale = math.mapRange(dd, 0, 200, 1, 5);

		// push force
		dx = this.x - cursor.x;
		dy = this.y - cursor.y;
		dd = Math.sqrt(dx * dx + dy * dy);

		distDelta = this.minDist - dd;

		if (dd < this.minDist) {
			this.ax += (dx / dd) * distDelta * this.pushFactor;
			this.ay += (dy / dd) * distDelta * this.pushFactor;
		}

		this.vx += this.ax;
		this.vy += this.ay;

		this.vx *= this.dampFactor;
		this.vy *= this.dampFactor;

		this.x += this.vx;
		this.y += this.vy;



	}

	wobble() {
		// wobble
		this.ax += random.range(0.01, 0.1)
		this.ay += random.range(0.01, 0.1)

		this.vx += this.ax;
		this.vy += this.ay;

		this.vx *= this.dampFactor;
		this.vy *= this.dampFactor;

		this.x += this.vx;
		this.y += this.vy;
	}

	draw(context) {
		context.save();
		context.translate(this.x, this.y);
		context.fillStyle = this.color;

		context.beginPath();
		context.arc(0, 0, this.radius * this.scale, 0, Math.PI * 2);
		context.fill();

		context.restore();
	}
}
