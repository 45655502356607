import React, { createRef, useEffect } from 'react';

// const width = 400
// const height = 400

const randomRange = (min,max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min ) + min);
}

let settings = {}

settings.width = 1080 
settings.height = 720
settings.buffer = 700
settings.speed = 2

export default function BouncingPoints() {
  
	// const canvasReference = createRef();
	const canvasReference = React.useRef(null);

	const agents = []

	for (let i = 0; i < 15; i++) {
			const x = randomRange(0 + settings.buffer, settings.width - settings.buffer);
			const y = randomRange(0 + settings.buffer, settings.height - settings.buffer);

			agents.push(new Agent(x, y));
		
	}
	
	//gets called every frame
	const draw = (context, frameCount) => {
		const width = context.canvas.width
		const height = context.canvas.height

		// context.fillStyle = 'white'
		// context.fillRect(0, 0, width, height);

		context.clearRect(0, 0, width, height);
		context.fillStyle = 'red';

		let lineWidth = 2

		for (let i = 0; i < agents.length; i++) {
			const agent = agents[i];

			for (let j = i + 1; j < agents.length; j++) {
				const other = agents[j];

				const dist = agent.pos.getDistance(other.pos);
				lineWidth = 1000/dist
				if (lineWidth > 5) {lineWidth = 5}
				context.lineWidth = lineWidth;
				context.strokeStyle = 'white'

				context.shadowColor = 'red'
				context.shadowBlur = 15
				

				context.beginPath();
				context.moveTo(agent.pos.x, agent.pos.y);
				context.lineTo(other.pos.x, other.pos.y);
				context.stroke();
			}
		}

		agents.forEach(agent => {
			// agent.updateVel();
			agent.updatePos();
			agent.draw(context);
			agent.bounce(width, height);
		});

	};

	const onMouseDown = () => {
		agents.forEach(agent => {
			agent.flip();
			// agent.shootUp()
		})
	}

	useEffect(() => {
		const canvas = canvasReference.current;
		const context = canvas.getContext('2d');
		context.canvas.width = settings.width
		context.canvas.height = settings.height
		canvas.addEventListener('mousedown', onMouseDown)

		let frameCount = 0;
		let animationFrameId;
		const render = () => {
			frameCount++;
			draw(context, frameCount);
			animationFrameId = window.requestAnimationFrame(render);
		};
		render();
		return () => {
			window.cancelAnimationFrame(animationFrameId);
			canvas.removeEventListener('mousedown', onMouseDown)
		};
	});
	
	return <canvas style={{ width: '100%' , height: '100%' }} ref={canvasReference} />;
}

class Vector {
	constructor(x, y) {
		this.x = x;
		this.y = y;
	}

	getDistance(v) {
		const dx = this.x - v.x;
		const dy = this.y - v.y;
		return Math.sqrt(dx * dx + dy * dy);
	}
}

class Agent {
	constructor(x, y) {
		this.pos = new Vector(x, y);
    	this.speed = settings.speed
		this.vel = new Vector(randomRange(-this.speed, this.speed), randomRange(-this.speed, this.speed));
		this.radius = randomRange(4, 12);
	}

	bounce(width, height) {
		if (this.pos.x - this.radius <= 0 || this.pos.x + this.radius >= width)  this.vel.x *= -1;
		if (this.pos.y - this.radius <= 0 || this.pos.y + this.radius >= height) this.vel.y *= -1;
	}

	flip() {
		this.vel.x *= -2;
		this.vel.y *= -2;
	}

	shootUp() {
		this.vel.y += 20
	}

	updateVel() {
		this.vel.y += 1;
	}

	updatePos() {
		this.pos.x += this.vel.x;
		this.pos.y += this.vel.y;
	}

	draw(context) {
		context.save();
		context.translate(this.pos.x, this.pos.y);

		context.lineWidth = 4;

		context.beginPath();
		context.arc(0, 0, this.radius, 0, Math.PI * 2);
		context.fill();
		context.stroke();

		context.restore();
	}
}