/* eslint-disable */

import React from 'react';
import styled, { keyframes, css } from 'styled-components'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import AnimationHeading from '../animation/AnimateHeading';
import {Section, H3, Text, MyTheme} from '../style/MyTags'

import logoPython from '../../images/languages/python.png'
import logoSQL from '../../images/languages/sql.png'
import logoReact from '../../images/languages/react.png'
import logoHTML from '../../images/languages/html.png'
import logoCSS from '../../images/languages/css.png'
import logoJS from '../../images/languages/js.png'
import logoC from '../../images/languages/c.png'
import logoVBA from '../../images/languages/vba.png'
import logoMATLAB from '../../images/languages/matlab.png'

export default function SkillsLanguages() {

	const languages = [
		{ logo: logoPython, name: 'python' },
		{ logo: logoSQL, name: 'sql' },
		{ logo: logoReact, name: 'react' },
		{ logo: logoJS, name: 'js' },
		{ logo: logoC, name: 'c' },
		{ logo: logoHTML, name: 'html' },
		{ logo: logoCSS, name: 'css' },
		{ logo: logoMATLAB, name: 'matlab' },
		{ logo: logoVBA, name: 'vba' }
	]

	const handleClick = (name) => {
		console.log(name)
		let url = `https://www.google.com/search?q=${name}+programming+language`
		window.open(url, '_blank');
	}

	const containerRef = React.useRef(null)

	const handleScrollLeft = () => {
		if (containerRef.current) {
			containerRef.current.scrollBy({ left: -200, behavior: 'smooth' })
		}
	}

	const handleScrollRight = () => {
		if (containerRef.current) {
			containerRef.current.scrollBy({ left: 200, behavior: 'smooth' })
		}
	}

	return (
		<>
			<Section>
				<AnimationHeading text='Programming Languages'/>
				<Text>Experience with various widely used programming languages. </Text>
				<NoOverflowContainer>
					<MainContainer>
						<LeftArrow onClick={handleScrollLeft}><FaAngleLeft /></LeftArrow>
						<Container len={languages.length} ref={containerRef}> 
							{languages.map((language, index) => (
								<ImageHolder key={'language' + index}>
									<Image src={language.logo} onClick={() => handleClick(language.name)} />
								</ImageHolder>
							))}
						</Container>
						<RightArrow onClick={handleScrollRight}><FaAngleRight /></RightArrow>
					</MainContainer>
				</NoOverflowContainer>
			</Section>
		</>
	);
}

const animationInitial = keyframes`
  0% { opacity: 0; transform: translateY(200px) skewX(10deg) skewY(10deg) ; filter: blur(10px); }
  100% { opacity: 1; transform: translateY(0px) skewX(0deg) skewY(0deg); filter: blur(0px); }
`
const animationWave = keyframes`
  0% { opacity: 1; transform: translateX(0px) ; filter: blur(0px); }
  7% { opacity: 1; transform: translateX(15px) skewX(0) skewY(-0)  ; filter: blur(0px); }
  17%, 100% { opacity: 1; transform: translateX(0px) skewX(0deg) skewY(0deg) rotate(0deg) ; filter: blur(0px); }

`
const createCSS = (len, delay) => {
  	let styles = '';
	for (let i = 1; i <= len; i += 1) {
		styles += `
		div:nth-child(${i}) {
			animation-delay: ${i * delay}s;
		}
		`
	}
  	return css`${styles}`;
}

const NoOverflowContainer = styled.div`
	overflow: hidden;
`

const MainContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	

	opacity: 0;
	animation-delay: 1.5s;
	animation-name: ${animationInitial};
	animation-duration: 3s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;

`

const Container = styled.div`
	display: flex;
	overflow-x: auto;
	padding: 15px 10px;
	text-align: left;

	&::-webkit-scrollbar {
		display:none
	}

	div {
		animation-name: ${animationWave};
		animation-duration: ${props => (props.len + 's')};
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
	}

	/* adds animation delay css */
	${props => createCSS(props.len, 0.2)}
`

const ImageHolder = styled.div`
	display: flex;
	flex: 1;
	z-index: 10;
	background-color: white;
	justify-content: center;
	margin: 5px;
	text-align: left;

	&:hover {
		/* background-color: #202025; */
		background-color: ${MyTheme.bgColor.item};

	}
`

const Image = styled.img`
	display:inline-block; 
	min-height: 100px;
	min-width: 100px;
	height: calc(100vw / 8);
	width:  calc(100vw / 8);
	max-height:150px;
	max-width: 150px;
	flex: 1;
	object-fit: contain;
	transition: 0.6s ease all;

	cursor: pointer;
	&:hover {
		transform: scale(0.7);
		/* transition: transform 0.2s ease-out; */
	};
`

const Arrow = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 60px;
	height: 80px;
	font-size: 60px;
	z-index: 9999;
	color: white;
	background-color: rgba(32,32,37, 0.3);
	cursor: pointer;
	&:hover {
		color: ${MyTheme.color.btnHover};
		background-color: rgba(32,32,37, 0.5);
	}
`

const LeftArrow = styled(Arrow)`
	/* position: absolute; */
	align-self: flex-start;
`;

const RightArrow = styled(Arrow)`
	/* position: absolute; */
	align-self: flex-end;
`;