
const jobs = [
    {
      title:  'Operational Transport Forecaster',
      company: 'Transport for NSW',
      location: 'Sydney',
      date: '2021-current',
      description: [
        'Modernise the model development processes by automation',
        'Review transport models for clients throughout TfNSW',
        'Develop, calibrate and validate complex transport models',
        'Analyse large datasets to form the inputs of the model',
        'Manipulate outputs to create innovative and intuitive methods of displaying results',
      ]
    },
    {
      title:  'Engineer - Transport Modelling & Analytics',
      company: 'SMEC',
      location: 'Sydney',
      date: '2017-2021',
      description: [
        'Develop, calibrate and validate complex transport models',
        'Undertake detailed economic analysis for transport projects',
        'Analyse large datasets to form the inputs of the model',
        'Manipulate outputs to create innovative and intuitive methods of displaying results',
      ]
    },
    {
      title:  'Undergraduate Structural Engineer',
      company: 'SMEC',
      location: 'Sydney',
      date: '2016-2017',
      description: [
        'Use structural engineering software to model bridges',
        'Undertake bridge design calculation checks',
        'Automating spreadsheets used in previous projects'
      ]
    }
  ]

export default jobs