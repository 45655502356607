/* eslint-disable */

import React, {useState, useRef} from 'react';
import styled, { keyframes } from 'styled-components'

import { Section, Gap, MyTheme, PageContainer } from '../components/style/MyTags';
import AnimateHeading from '../components/animation/AnimateHeading';

import ParticleVisualiser from '../components/visualiser/ParticleVisualiser';

export default function Visualiser() {

	const gap = 10;

  return (
	<PageContainer>

		<Section style={{padding: '5px 5px', backgroundColor: 'black'}}>
			{/* <AnimateHeading text='Particle Visualiser' /> */}
			<SphereHolder>
				<ParticleVisualiser />
			</SphereHolder>

		</Section>
		{/* <Gap px={gap}/> */}

	</ PageContainer>
  );
}

// const CanvasHolder= styled.div`
// 	display: flex;
// 	justify-content: center;
// 	overflow: 'auto',
// `
const SphereHolder = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 65px);
	/* width: 80vw; */
	position: relative;
	/* max-height: 600px; */
	overflow: hidden;
`;
