import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import axios from "axios";
import { MyTheme } from '../style/MyTags';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// import image from '../../images/portrait/image-03.png'
// import image from '../../images/Mandala64.jpg'
import image from '../../images/Mandala3.jpg'
// import image from '../../images/NotMyMandala.jpg'

let colourProp = {
	hue: { max: 360 },
	saturation: { max: 100 },
	brightness: { max: 100 }
}
let mods = {x: 'none', y: 'none', z: 'hue', 
	scale: {on: true, property:'brightness', range: {min: 1, max: 3}}, 
	zDepth: {static: 24, animated:15},
	rotate: {on: true, speed:{x:0.02, y: 0.02, z: 0.02} }
	
}
let presets = ['person', 'colourful']
let preset = 'colourful'

if(preset === 'person') {
	mods = {x: 'none', y: 'none', z: 'brightness', scale: {on: true, property:'brightness', range: {min: 0.8, max: 3}}, zDepth: {static: 10, animated: 15}, rotate: {on: true, speed:{x:0.02, y: 0.02, z: 0.02}  }}
} else if (preset === 'colourful') {
	mods = {x: 'none', y: 'none', z: 'hue', scale: {on: true, property:'brightness', range: {min: 1, max: 3}}, zDepth: {static: 24, animated: 20}, rotate: {on: true, speed:{x:0.02, y: 0.02, z: 0.02}  }}
}

const nPixels = {x: 64, y: 64}

let imgData

const requestChatGPT = () => {
	
	const HTTP = "http://localhost:8080/chat";
	let instruct = 'Be as concise as possible.'
	let request = 'What are the top three biggest companies by revenue?'
	let prompt = instruct + ' ' + request

	axios
	.post(`${HTTP}`, { prompt })
	.then((res) => {
		console.log(prompt);
		console.log(removeLineBreaks(res.data)) 
	})
	.catch((error) => {
		console.log(error);
	});

};

function removeLineBreaks(text) {
	return text.replace(/^\n+|\n+$/g, '');
}

const GridOfCubes = () => {
	const containerRef = useRef(null);
	const sceneRef = useRef(null);
	const rendererRef = useRef(null);
	const controlsRef = useRef(null);

	// requestChatGPT()

	useEffect(() => {

		const imgCanvas = document.createElement('canvas');
		const imgContext = imgCanvas.getContext('2d');
		const start = async () => {
		  // img = await loadImage(image)
		  const resizedImage = await resizeImage(image, nPixels.x, nPixels.y);
		  const img = new Image();
		  img.src = resizedImage;
		  img.onload = function () {
			imgCanvas.width = img.width;
			imgCanvas.height = img.height;
			imgContext.drawImage(img, 0, 0);
			imgData = imgContext.getImageData(0, 0, img.width, img.height).data;
			console.log(img.width, img.height);
			// console.log(imgData)
			sketch();
		  };
		};
		start();

		// Clean up on unmount
		return () => {
			if (controlsRef.current) {
				controlsRef.current.dispose();
			}
			if (sceneRef.current) {
			sceneRef.current.clear();
			}
			if (rendererRef.current) {
			rendererRef.current.dispose();
			}
			if (containerRef.current && rendererRef.current && rendererRef.current.domElement) {
			containerRef.current.removeChild(rendererRef.current.domElement);
			}
		};

	}, []);

	const sketch = () => {

		const container = containerRef.current;
		const dimension = Math.min(container.clientWidth, container.clientHeight)

		// Set up the scene, camera, and renderer
		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera();
		const renderer = new THREE.WebGLRenderer({ antialias: true });
		renderer.setSize(dimension, dimension);
		containerRef.current.appendChild(renderer.domElement);


		// Create an instance of OrbitControls
		const controls = new OrbitControls(camera, container);
		controls.enableZoom = true;
		controls.enablePan = true;
		controls.enableRotate = true;
		controls.screenSpacePanning = true;
		controls.zoomToCursor = true;

		// Save references to the scene and renderer
		sceneRef.current = scene;
		rendererRef.current = renderer;
		controlsRef.current = controls;

		// scene.background = new THREE.Color('#cecece');
		scene.background = new THREE.Color('black');
		// scene.background = new THREE.Color(`${MyTheme.bgColor.section}`);

		// Create the grid of cubes
		const cubeSize = 1;
		const spacing = 1;
		const gridSize = 64
		const cubes = [];

		let x, y, ix, iy, idx, r, g, b;

		for (let i = 0; i < gridSize; i++) {
			for (let j = 0; j < gridSize; j++) {

				//2d positions of objects
				x = i * (cubeSize + spacing);
				y = j * (cubeSize + spacing);

				//process colour by traversing 1D pixel data array from img like a 2D matrix
				let color = new THREE.Color();
				ix = Math.floor((i / gridSize) * nPixels.x)
				iy = Math.floor((((gridSize - 1) - j) / gridSize) * nPixels.y)
				idx = (iy * 64 + ix) * 4; //4 used because imgData returned as RGBA
				r = imgData[idx + 0];
				g = imgData[idx + 1];
				b = imgData[idx + 2];
				color.setRGB(r/255, g/255, b/255)
				let hsvArray = rgbToHsv(r, g, b);
				let colorHSV = {
					hue: hsvArray[0],
					saturation: hsvArray[1],
					brightness: hsvArray[2]
				};

				//define object geometry
				const geometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);

				//choose multiple different geometries based on criteria
				// let geometry
				// if(colorHSV.hue > 180) {
				// 	geometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);
				// } else {
				// 	const radius = cubeSize / 2; // Assuming cubeSize represents the width, height, and depth of the cube
				// 	geometry = new THREE.SphereGeometry(radius, 32, 32); // Adjust the number of segments (both horizontal and vertical) based on your needs
				// }

				// const geometry = createRoundedBox(cubeSize, cubeSize, cubeSize, 0.2 * cubeSize, 16);

				// const radius = cubeSize / 2; // Assuming cubeSize represents the width, height, and depth of the cube
				// const geometry = new THREE.SphereGeometry(radius, 32, 32); // Adjust the number of segments (both horizontal and vertical) based on your needs
				
				// const radius = (cubeSize * Math.sqrt(3)) / 3; // Calculate the radius for an equilateral triangle
				// const height = (2 * cubeSize) / Math.sqrt(3); // Calculate the height for an equilateral triangle
				// const geometry = new THREE.ConeGeometry(radius, height, 3); // Specify the number of segments as 3 for a triangular pyramid

				//assign material
				const material = new THREE.MeshPhongMaterial({ color: color});
				const cube = new THREE.Mesh(geometry, material);

				//position object (initial)
				cube.position.x = x
				cube.position.y = y
				if(mods.z === 'hue'){
					cube.position.z = mods.zDepth.static * normaliseVal(colorHSV[mods.z], colourProp[mods.z].max, 0, 1, false)
				} else if (mods.z === 'brightness'){
					cube.position.z = mods.zDepth.static * normaliseVal(colorHSV[mods.z], colourProp[mods.z].max, 0, 1, false)
				} else if (mods.z === 'saturation'){
					cube.position.z = mods.zDepth.static * normaliseVal(colorHSV[mods.z], colourProp[mods.z].max, 0, 1, false)
				}

				//scale object (initial)
				let scaleInit = normaliseVal(colorHSV[mods.scale.property], colourProp[mods.z].max, mods.scale.range.min, mods.scale.range.max, false)
				cube.scale.set(scaleInit, scaleInit, scaleInit)

				//create new variables for visual object
				cube.userData.xInit = x
				cube.userData.yInit = y
				cube.userData.zInit = cube.position.z
				cube.userData.zDirection = 1;
				if (colorHSV.hue < (colourProp.hue.max / 2)) cube.userData.zDirection = -1
				cube.userData.scaleDirection = 1;
				cube.userData.rotationDirection = 1;
				if(colorHSV.brightness < 80) cube.userData.rotationDirection = -1
				// if(colorHSV[0] > 40 && colorHSV[0] < 300) cube.userData.rotationDirection = -1
				cube.userData.color = colorHSV

				cubes.push(cube);
				scene.add(cube);
			}
		}

		// Position the camera
		const gridWidth = gridSize * (cubeSize + spacing);
		const gridHeight = gridSize * (cubeSize + spacing);
		const gridDepth = cubeSize;
		const maxGridSize = Math.max(gridWidth, gridHeight, gridDepth);
		const gridCenterX = (gridSize - 1) * (cubeSize + spacing) / 2;
		const gridCenterY = (gridSize - 1) * (cubeSize + spacing) / 2;
		const gridCenterZ = maxGridSize * 1.1
		controls.target.set(gridCenterX, gridCenterY, 0); //look here
		camera.position.set(gridWidth, gridCenterY * 0.8, gridCenterZ); // from here
		camera.lookAt(new THREE.Vector3(gridCenterX, gridCenterY, 0)); // point the camera

		// Point light for visibility
		const light = new THREE.PointLight('white', 2);
		light.position.set(gridCenterX * 1.3, gridHeight, 30);
		scene.add(light);

		const light2 = new THREE.PointLight('white', 2);
		light2.position.set(gridCenterX, -gridHeight, 30);
		scene.add(light2);

		// const light2 = new THREE.PointLight('white', 2);
		// light2.position.set(gridCenterX, gridCenterY, 30);
		// scene.add(light2);

		// Render the scene
		const animate = () => {
			requestAnimationFrame(animate);

			// rotate(cubes, mods.rotateSpeed, mods.rotateSpeed, mods.rotateSpeed)
			// scaleObjects(cubes,'scale.x', mods.scale.range.min, mods.scale.range.max, 0.01,'scaleDirection')
			loopParameter(cubes,'position.z', 0, mods.zDepth.animated, 0.1,'zDirection')
			// scatterObjects(cubes, 1, 3, 0.0001)

			cubes.forEach(object => {

				if(mods.rotate.on){
					object.rotation.x += (mods.rotate.speed.x * object.userData.rotationDirection)
					object.rotation.y += (mods.rotate.speed.y * object.userData.rotationDirection)
					object.rotation.z += (mods.rotate.speed.z * object.userData.rotationDirection)
				}

				if(mods.scale.on){
					let updatedValue = animateProperty(object.scale.x,mods.scale.range.min, mods.scale.range.max, 0.01, object.userData.scaleDirection)
					object.scale.setScalar(updatedValue.value)
					object.userData.scaleDirection = updatedValue.direction
				}


			})

			renderer.setSize(dimension, dimension);
			renderer.render(scene, camera);
		};
		animate();
	}

	return (<div
		style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}
		ref={containerRef} />);
};

export default GridOfCubes;

// const scaleObjects = (objects, minScale, maxScale, stepSize) => {
// 	objects.forEach(object => {
// 	  const scale = object.scale.x + stepSize * object.userData.scaleDirection;
// 	  if (scale > maxScale || scale < minScale) object.userData.scaleDirection *= -1;
// 	  object.scale.set(scale, scale, scale);
// 	});
// };

// const scaleObjects = (objects, minScale, maxScale, stepSize) => {
// 	objects.forEach(object => {
// 		const updatedValue = animateProperty(object.scale.x, minScale, maxScale, stepSize, object.userData.scaleDirection);
// 		object.scale.set(updatedValue.value, updatedValue.value, updatedValue.value);
// 		object.userData.scaleDirection = updatedValue.direction;
// 	});
// };

const scaleObjects = (objects, property, minScale, maxScale, stepSize, direction) => {
	objects.forEach(object => {
		const properties = property.split('.'); // Split the layered property by dot (.)
		let currentProperty = object;
	
		// Traverse the object to access the nested property
		for (const prop of properties) {currentProperty = currentProperty[prop]}
		
		let updatedValue;
		if (direction === undefined || direction === null) {
			updatedValue = animateProperty(currentProperty, minScale, maxScale, stepSize);
		} else {
			const scaleDirection = object.userData[direction];
			updatedValue = animateProperty(currentProperty, minScale, maxScale, stepSize, scaleDirection);
			object.userData[direction] = updatedValue.direction;
		}
		object.scale.setScalar(updatedValue.value)
	});
};

const loopParameter = (objects, property, minScale, maxScale, stepSize, direction) => {
	objects.forEach(object => {
		const properties = property.split('.'); // Split the layered property by dot (.)
		let currentProperty = object;
	
		// Traverse the object to access the nested property
		for (const prop of properties) {currentProperty = currentProperty[prop]}
		
		let updatedValue;
		if (direction === undefined || direction === null) {
			updatedValue = animateProperty(currentProperty, minScale, maxScale, stepSize);
		} else {
			const scaleDirection = object.userData[direction];
			updatedValue = animateProperty(currentProperty, minScale, maxScale, stepSize, scaleDirection);
			object.userData[direction] = updatedValue.direction;
		}

		// Update the nested property with the new value
		let targetProperty = object;
		for (let i = 0; i < properties.length - 1; i++) {
			targetProperty = targetProperty[properties[i]];
		}
		targetProperty[properties[properties.length - 1]] = updatedValue.value;
	});
};

const animateProperty = (property, minValue, maxValue, stepSize, direction) => {
	let value = property;
	let newDirection = direction;
  
	if (direction === undefined || direction === null) {
	  value += stepSize;
	  if (value > maxValue) {
		value = minValue;
	  }
	} else {
	  value += stepSize * direction;
	  if (value > maxValue || value < minValue) {
		newDirection = -direction;
		value = Math.max(minValue, Math.min(maxValue, value));
	  }
	}
  
	return { value, direction: newDirection };
};
  

const rotate = (objects, x=0.01, y=0.01, z=0) => {
	objects.forEach(object => {
		object.rotation.x += (x * object.userData.rotationDirection)
		object.rotation.y += (y * object.userData.rotationDirection)
		object.rotation.z += (z * object.userData.rotationDirection)
	})
}

function createRoundedBox(width, height, depth, radius, segments) {
	const shape = new THREE.Shape();
	const eps = 0.00001;
	const sqrt = Math.sqrt;

	shape.absarc( eps, eps, eps, -Math.PI / 2, -Math.PI, true );
	shape.absarc( eps, height -  radius * 2, eps, Math.PI, Math.PI / 2, true );
	shape.absarc( width - radius * 2, height -  radius * 2, eps, Math.PI / 2, 0, true );
	shape.absarc( width - radius * 2, eps, eps, 0, -Math.PI / 2, true );

	const geometry = new THREE.ExtrudeGeometry( shape, {
		depth: depth - radius * 2,
		bevelEnabled: true,
		bevelSegments: segments,
		steps: 1,
		bevelSize: radius,
		bevelThickness: radius,
		curveSegments: segments
	});

	geometry.center();

	return geometry;
  }
  
  // need to scatter in different direction from the center
const scatterObjects = (objects, minScale, maxScale, stepSize) => {
	objects.forEach(object => {
		object.position.x += stepSize
		object.position.y += stepSize
		if(object.position.x >= (object.userData.xInit * maxScale)) {
			object.position.x = object.userData.xInit * minScale
			object.position.y = object.userData.yInit * minScale
		}
	});
}

const loadImage = async (url) => {
	return new Promise((resolve, reject) => {
		const img = new Image()
		img.src = url
		img.onload = () => resolve(img)
		img.onerror = () => reject()
	})
}

const resizeImage = (image, w, h) => {
	return new Promise((resolve, reject) => {
		// Create an Image object
		const imageTest = new Image();

		// Set the source of the image
		imageTest.src = image;

		// Wait for the image to load
		imageTest.onload = function() {
			const canvas = document.createElement('canvas');
			canvas.width = w;
			canvas.height = h;
			const ctx = canvas.getContext('2d');

			// Draw the image onto the canvas
			ctx.drawImage(imageTest, 0, 0, w, h);

			// Get the resized image as a base64-encoded string
			const resizedImage = canvas.toDataURL('image/jpeg');
			// console.log(resizedImage)

			// Resolve the promise with the resized image
			resolve(resizedImage);
		};

		// Handle errors if the image fails to load
		imageTest.onerror = function() {
			reject(new Error('Failed to load image'));
		};
	});
};

function rgbToHsv(r, g, b){
    r /= 255
	g /= 255
	b /= 255

    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, v = max;

    let diff = max - min;
    s = max === 0 ? 0 : diff / max;

    if(max === min){
        h = 0; // achromatic
    } else {
        switch(max){
            case r: h = (g - b) / diff + (g < b ? 6 : 0); break;
            case g: h = (b - r) / diff + 2; break;
            case b: h = (r - g) / diff + 4; break;
			default: h = 0; break;
        }
        h /= 6;
    }

    return [ h * 360, s * 100, v * 100 ];
}

function normaliseVal(val, maxVal, minTargetValue, maxTargetValue, reverse = false) {
	let scaledVal
	if (minTargetValue <= maxTargetValue) {
		scaledVal = (minTargetValue + (val / maxVal) * (maxTargetValue - minTargetValue))
	} else {
		scaledVal = (maxTargetValue - (val / maxVal) * (minTargetValue - maxTargetValue))
	}
	if(reverse === true) return minTargetValue + maxTargetValue - scaledVal
	return 	scaledVal

}