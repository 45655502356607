/* eslint-disable */

import React from 'react';
import styled from 'styled-components'

import { Section, Gap, Text, PageContainer, Bounce } from '../components/style/MyTags';
import AnimateHeading from '../components/animation/AnimateHeading';

import ParticlePortrait from '../components/interactive/ParticlePortrait'

export default function ParticlePortraitScreen() {

	const gap = 10;

  return (
	<PageContainer>

		<Section>
			<AnimateHeading text='Particle Portrait' />
			<Bounce>Click and swipe to move particles </Bounce>
			<CanvasHolder>
				<ParticlePortrait 	/>
			</CanvasHolder>
		</Section>
		<Gap px={gap}/>	

	</ PageContainer>
  );
}

const CanvasHolder= styled.div`
	display: flex;
	justify-content: center;
	/* overflow: 'auto', */
`