/* eslint-disable */

import React, {useState, useRef} from 'react';
import styled, { keyframes } from 'styled-components'

import { Section, Gap, Text, MyTheme, PageContainer} from '../components/style/MyTags';
import AnimateHeading from '../components/animation/AnimateHeading';

import SentimentAnalysis from '../components/ai/SentimentAnalysis';
import ChatGPT from '../components/ai/ChatGPT';

export default function AIScreen() {

	const gap = 10;

	return (
		<PageContainer>

			<Section>
				<AnimateHeading text='ChatGPT API' />
				<ChatGPT />
			</Section>
			<Gap px={gap}/>

			<Section>
				<AnimateHeading text='Sentiment Analysis' />
				<SentimentAnalysis />
			</Section>
			<Gap px={gap}/>

			{/* <Section>
				<AnimateHeading text='Image Classifier' />
				<ImageClassifier />
			</Section>
			<Gap px={gap}/> */}

		</ PageContainer>
	);
}
