/* eslint-disable */

import React from 'react';
import styled from 'styled-components'

import { Section, H3, Bounce, Gap, MyTheme} from '../style/MyTags';
import AnimationHeading from '../animation/AnimateHeading';

import logoUNSW from '../../images/logos/unsw.png'

export default function Education() {

	const education = [

		{
			title:  'MASTER OF INFORMATION TECHNOLOGY',
			major: 'in Artificial Intelligence',
			grade: 'TBD',
			university: 'UNSW',
			logo: logoUNSW,
			location: 'Sydney',
			date: '2020-current',
			url: 'https://www.handbook.unsw.edu.au/postgraduate/specialisations/2023/COMPAS'
		},
		{
			title:  'BACHELOR OF ENGINEERING',
			major: 'in Civil Engineering',
			grade: 'with Honours',
			university: 'UNSW',
			logo: logoUNSW,
			location: 'Sydney',
			date: '2012-2016',
			url: 'https://www.handbook.unsw.edu.au/undergraduate/specialisations/2023/CVENAH?year=2023'
		},
	]

	

	return (
		<>
		<Section>
			<AnimationHeading text='Education' />
			<Bounce>click any to view courses</Bounce> 
			<FlexContainer>
				{education.map((degree, index) => (
					<Certificate key={'degree' + index} onClick={() => window.open(degree.url, '_blank')}>
						<ImageHolder>
							<Image src={degree.logo} />
						</ImageHolder>
						<Title>{degree.title}</Title>
						<Text>{degree.major}</Text>
						<SplitText>
							<TextGrade>{degree.grade}</TextGrade>
							<TextDate>{degree.date}</TextDate>
						</SplitText>
						<Gap px={10} />
					</Certificate>
				))}
			</FlexContainer>

		</Section>

		</>
	);
}

const FlexContainer = styled.div`
	display: flex;
	overflow-x: auto;
	padding: 15px 10px;
	margin: 5px;
	border-radius: 4px;
	text-align: left;
	/* background-color: white; */
	background-color: ${MyTheme.bgColor.item};

`
const Certificate = styled.div`
	flex: 1;
	background-color: #202025;
	border-radius: 5px;
	padding: 5px;

	transform:
		rotateY(-20deg)
		rotateX(20deg)
		/* rotateZ(10deg) */
		scale(0.9)
		/* translateX(20px) */
	;
	transition: 1s ease all;
	box-shadow: 1rem 1rem 1rem rgba(0,0,0,0.25);

	&:hover {
		transform:
			rotateY(0)
			rotateX(0deg)
			rotateZ(0)
			scale(1);
		box-shadow: none;
		cursor: pointer;
	}
/* 
	&:hover img {
		transform: scale(1.0);
		transition: 0.2s ease-out;
  	}; */

`

const ImageHolder = styled.div`
	display: flex;
	background-color: rgb(217, 219, 207);
	justify-content: center;
	margin: 5px;
	text-align: left;
`

const Image = styled.img`
  display:inline-block;
  min-height: 100px;
  min-width: 100px;
  height: calc(100vw / 8);
  width:  calc(100vw / 8);
  max-height:150px;
  max-width: 150px;
  flex: 1;
  object-fit: contain;



`

const Title = styled(H3)`
  	padding: 5px 15px;

`

export const Text = styled.p`
	font-size: 1em;
	margin: 0;
	padding: 0px 15px;
	color: ${MyTheme.color.text};
`
const SplitText = styled.div`
	display: flex;
	align-content: space-between;

`
const TextGrade = styled(Text)`
	flex: 1;
	text-align: left;
`

const TextDate = styled(Text)`
	flex: 1;
	text-align: right;

`