import React from "react";
import styled, {keyframes} from "styled-components";
import { MyTheme } from '../style/MyTags';

export default function AnimationHeading(props) {

    return (
        <Wrapper>{props.text}</Wrapper>
    )

}

const animation = keyframes`
  0% { opacity: 0; transform: translateY(-100px) skewX(10deg) skewY(10deg) rotateZ(30deg); filter: blur(10px); }
  100% { opacity: 1; transform: translateY(0px) skewX(0deg) skewY(0deg) rotateZ(0deg); filter: blur(0px); }
`

const Wrapper = styled.h1`
    display:inline-block;
    opacity: 1;
    font-size: calc(1.1em + 1.5vw);
    font-weight: bold;
    text-shadow: 1px 1px 8px ${MyTheme.shadow.h1};

    opacity: 0;
    animation-delay: 1s;
    animation-name: ${animation};
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    color: ${MyTheme.color.h1};
`